@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300&family=Montserrat&family=Mulish&family=Nunito&family=Open+Sans+Condensed:wght@300&family=Poppins:wght@300&family=Roboto&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-file-upload-button {
  background: rgb(5, 150, 105);
  color: #fff;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 0.5rem;
  border: none;
}

[contenteditable="true"]:empty:before {
  content: "Write Something Here";
  color: grey;
  display: block;
}

body {
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
}

@layer components {
  .menu-item {
    @apply md:p-2 lg:px-4 font-semibold block px-4 py-1 text-center;
  }

  .special-text a {
    @apply text-blue-500 dark:text-blue-400 hover:text-blue-800 transition-all duration-200 font-semibold hover:underline;
  }

  .invitation-tab-bar-item {
    @apply text-green-400 cursor-pointer px-4 py-2 font-semibold;
  }

  .invitation-tab-bar-item-underbar {
    @apply h-1 bg-green-600 rounded-full;
  }

  .connection-screens-common-button-layout {
    @apply transition-all duration-300 tracking-wider px-2 py-1 rounded-3xl w-full;
  }

  .post-options {
    @apply p-3 text-center cursor-pointer hover:bg-green-400 hover:bg-opacity-30 rounded-full;
  }

  .post-option-tooltip {
    @apply hidden md:block text-sm 2xl:text-lg pt-1;
  }
}

/* For Mansory Grid */
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

.suggested-profiles-container::-webkit-scrollbar {
  width: 0px;
}

.scroller::-webkit-scrollbar {
  width: 15px;
}

.scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

.scroller::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.no-scroller::-webkit-scrollbar {
  width: 0px;
}